import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';
import { RefreshDataContext } from '../../App';
import { TextField, HiddenField, ReadonlyField } from './formikFields';
import { FormButtons, requiredStar } from '../../components/Form/formHelpers';
import QrReader from 'modern-react-qr-reader'
import * as Yup from 'yup';
import { Form, Button, Progress, Card, CardBody, Media, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { actionScanKit, actionDeleteTest, actionUpdateTest } from '../../actions/trials';
import { getAllLocationOptions, getTrialsDefaultLocation, getTrialLocationOptions } from '../Common/locations';
import { getThisTrialsSwabHistory } from '../Common/tests';
import rotateCameraSvg from "../../assets/images/icons/camera-reverse.svg";
import { actionLocations } from '../../actions/flags';

export function RegisterPatientKitForm(props) {
  const { trials, setActiveHomeTab, scannerLocations, swabHistory, setActiveTab } = props;
  const [formStep, setFormStep] = useState(1);
  const trialsOptions = trials.filter(f => f.status === 'ACTIVE').map((t) => { return({label: t.name, value: t.id}) });
  const locationOptions = getAllLocationOptions(trials, scannerLocations);
  const defaultTrial = trialsOptions.length > 0 ? trialsOptions[0].value : '';
  const defaultLocationForTrial = getTrialsDefaultLocation(defaultTrial, locationOptions);
  const validationSchema = Yup.object().shape({
    trialName: Yup.string()
      .required('This is a required field'),
    location: Yup.string()
      .required('This is a required field'),
    trialNameConfirm: Yup.string()
      .required('This is a required field'),
    locationConfirm: Yup.string()
      .required('This is a required field'),
    patientId: Yup.string()
      .required('This is a required field')
      .max(32, 'Must be 32 characters or less'),
    kitId: Yup.string()
      .required('This is a required field')
      .matches(/^(BA|PG|TR|JL|PY|MJ)(-[0-9]{4}){3}$/, "Invalid Swab ID"),
  })
  .test('duplicateKit', null, (obj) => {
    const thisTrial = trials.filter(t => t.id == obj.trialName)[0];
    const swabHistoryData = getThisTrialsSwabHistory(thisTrial, swabHistory);
    if(!!swabHistoryData.find(d => d.kitId === obj.kitId)) {
      return new Yup.ValidationError('This swab kit has already been scanned, please scan a different swab kit.', null, 'kitId');
    }
  })
  .test('wrongKitType', null, (obj) => {
    const thisTrial = trials.filter(t => t.id == obj.trialName)[0];
    let invalidKitType = false;
    if(obj.kitId && (/^(BA|PG|TR|JL|PY|MJ)(-[0-9]{4}){3}$/).test(obj.kitId) ) {
      invalidKitType = true;
      if(thisTrial.cbdTesting && (/^(BA|PG)(-[0-9]{4}){3}$/).test(obj.kitId)) invalidKitType = false;
      if(thisTrial.thcTesting && (/^(TR|JL)(-[0-9]{4}){3}$/).test(obj.kitId)) invalidKitType = false;
      if(thisTrial.cannabisTesting && (/^(MJ)(-[0-9]{4}){3}$/).test(obj.kitId)) invalidKitType = false;
      if(thisTrial.psychedelicTesting && (/^(PY)(-[0-9]{4}){3}$/).test(obj.kitId)) invalidKitType = false;
    }
    if(invalidKitType) return new Yup.ValidationError('This is not the correct type of swab kit for this trial.', null, 'kitId');
  });
  const serverErrorFields = {};
  const defaultTrialName = window.localStorage.getItem("defaultTrialName");
  const defaultLocation = window.localStorage.getItem("defaultLocation");
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          trialName: defaultTrialName ? defaultTrialName : defaultTrial ? defaultTrial : '',
          location: defaultLocation ? defaultLocation : defaultLocationForTrial ? defaultLocationForTrial : '',
          trialNameConfirm: defaultTrialName ? defaultTrialName : '',
          locationConfirm: defaultLocation ? defaultLocation : '',
          patientId: '', kitId: ''
        }}
        validationSchema={validationSchema}
      >
        {({values, errors, touched, resetForm, handleChange,
            handleBlur, handleSubmit, isSubmitting, validateField, setFieldTouched, setFieldValue}) => {
          const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
          return (
            <Form onSubmit={handleSubmit}>
              { formStep === 1 && <Step1 formVars={formVars} errors={errors} touched={touched} setFieldTouched={setFieldTouched}
                                         setFormStep={setFormStep} values={values} trialsOptions={trialsOptions}
                                         locationOptions={locationOptions} setFieldValue={setFieldValue} />}
              { formStep === 2 && <Step2 formVars={formVars} errors={errors} touched={touched} setFieldTouched={setFieldTouched}
                                         setFormStep={setFormStep} values={values} trialsOptions={trialsOptions}
                                         locationOptions={locationOptions} setFieldValue={setFieldValue} />}
              { formStep === 3 && <Step3 formVars={formVars} errors={errors} touched={touched} setFieldTouched={setFieldTouched}
                                         setFormStep={setFormStep} />}
              { formStep === 4 && <Step4 formVars={formVars} errors={errors} touched={touched} setFieldTouched={setFieldTouched}
                                         setFormStep={setFormStep} values={values} setFieldValue={setFieldValue}
                                         scannerLocations={scannerLocations} />}
              { formStep === 5 && <Step5 formVars={formVars} errors={errors} touched={touched} setFieldTouched={setFieldTouched}
                                         setFormStep={setFormStep} values={values} trials={trials} setActiveTab={setActiveTab}
                                         resetForm={resetForm} setActiveHomeTab={setActiveHomeTab} scannerLocations={scannerLocations} />}
            </Form>
          )}}
      </Formik>
    </>
  )
}

export function Step1(props) {
  const { formVars, errors, touched, setFieldTouched, setFormStep, values, trialsOptions, locationOptions, setFieldValue } = props;
  const storedDefaultsExist = !!(window.localStorage.getItem("defaultTrialName") && window.localStorage.getItem("defaultLocation"));
  return (
    <>
      <div className="text-center">
        <h4 className="my-4 ml-4">Group and Location</h4>
      </div>
      { trialsOptions.length ?
        <>
          <div>
            <HiddenField field="trialName" />
            <DropdownSelect selectText="Please select a group:" dropdownData={trialsOptions} value={values.trialName} setValue={(value) => setFieldValue('trialName', value)} error={errors && errors.trialName} />
            { errors && errors.trialName && <div className="text-center mt-1" style={{color: '#f46a6a'}}>{errors.trialName}</div> }
          </div>
          <div className="my-4">
            <HiddenField field="location" />
            <DropdownSelect selectText="Please select a location:" dropdownData={getTrialLocationOptions(locationOptions, values.trialName)} value={values.location} setValue={(value) => setFieldValue('location', value)} error={errors && errors.location} />
            { errors && errors.location && <div className="text-center mt-1" style={{color: '#f46a6a'}}>{errors.location}</div> }
          </div>

          <div className="text-right mt-5 mx-3">
            <Button color="primary" className="btn-block p-3"
              style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
              onClick={() => {
                if(errors.trialName || errors.location || !touched.trialName || !touched.location) {
                  setFieldTouched('trialName', true, true);
                  setFieldTouched('location', true, true);
                  if(!errors.trialName && !errors.location) {
                    setFieldValue('trialNameConfirm', values.trialName);
                    setFieldValue('locationConfirm', values.location);
                    !!values.trialName && window.localStorage.setItem("defaultTrialName", values.trialName);
                    !!values.location && window.localStorage.setItem("defaultLocation", values.location);
                    setFormStep(storedDefaultsExist ? 3 : 2);
                  }
                } else {
                  setFieldValue('trialNameConfirm', values.trialName);
                  setFieldValue('locationConfirm', values.location);
                  !!values.trialName && window.localStorage.setItem("defaultTrialName", values.trialName);
                  !!values.location && window.localStorage.setItem("defaultLocation", values.location);
                  setFormStep(storedDefaultsExist ? 3 : 2);
                }
            }}>Start Scanning</Button>
          </div>
        </> : <div className="text-center m-3 pb-3 font-weight-bold">
            <p className="py-5">There are currently no active trials available.</p>
        </div>
      }
    </>
  )
}

export function Step2(props) {
  const { formVars, errors, touched, setFieldTouched, setFormStep, values, trialsOptions, locationOptions, setFieldValue } = props;
  useEffect(() => { if(values.locationConfirm) {
    setFieldTouched('locationConfirm', true, true);
    setFieldTouched('trialNameConfirm', true, true);
  } else {return} }, []);
  return (
    <>
      <ProgressBar step={1} setFormStep={setFormStep} showBackButton={true} />
      <div className="text-center">
        <h4 className="my-3">Confirm group and location</h4>
      </div>

      <div>
        <HiddenField field="trialNameConfirm" />
        <DropdownSelect selectText="Please select a group" dropdownData={trialsOptions} value={values.trialNameConfirm} setValue={(value) => setFieldValue('trialNameConfirm', value)} error={errors && errors.trialNameConfirm} />
        { errors && errors.trialNameConfirm && <div className="text-center mt-1" style={{color: '#f46a6a'}}>{errors.trialNameConfirm}</div> }
      </div>
      <div className="my-4">
        <HiddenField field="locationConfirm" />
        <DropdownSelect selectText="Please select a location" dropdownData={getTrialLocationOptions(locationOptions, values.trialNameConfirm)} value={values.locationConfirm} setValue={(value) => setFieldValue('locationConfirm', value)} error={errors && errors.locationConfirm} />
        { errors && errors.locationConfirm && <div className="text-center mt-1" style={{color: '#f46a6a'}}>{errors.locationConfirm}</div> }
      </div>

      <div className="text-right mt-5 mx-3">
        <Button color="primary" className="btn-block p-3"
          style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
          onClick={() => {
            if(!(errors.trialNameConfirm || errors.locationConfirm)) {
              !!values.trialNameConfirm && window.localStorage.setItem("defaultTrialName", values.trialNameConfirm);
              !!values.locationConfirm && window.localStorage.setItem("defaultLocation", values.locationConfirm);
              setFormStep(3);
            }
          }}>
          {
            (values.trialNameConfirm != '' && !touched.trialNameConfirm) ||
            (values.locationConfirm != '' &&  !touched.locationConfirm) ?
            'Confirm values' : 'Next'
          }
        </Button>
      </div>
    </>
  )
}

export function Step3(props) {
  const { formVars, errors, touched, setFieldTouched, setFormStep } = props;
  return (
    <>
      <ProgressBar step={2} setFormStep={setFormStep} showBackButton={true} />
      <div className="text-center">
        <h4 className="my-3">Enter Subject ID {requiredStar}</h4>
      </div>
      <TextField key={1} fullWidth hideRequiredStar name="" field="patientId" placeholder="Subject's ID..." {...formVars} />
      <div className="text-right mt-5 mx-3">
        <Button color="primary" className="btn-block p-3"
          style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
          onClick={() => {
            if(errors.patientId || !touched.patientId) {
              setFieldTouched('patientId', true, true);
            } else {
              setFormStep(4);
            }
          }}>Next</Button>
      </div>
    </>
  )
}

export function Step4(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { formVars, errors, touched, setFieldTouched, setFormStep, values, setFieldValue, scannerLocations } = props;
  const dispatch = useDispatch();
  const [activateQrReader, setActivateQrReader] = useState(true);
  const [facingmode, setFacingmode] = useState("environment");
  const [manualInput, setManualInput] = useState(false);
  const [scanComplete, setScanComplete] = useState(false);
  const locationObj = (scannerLocations && scannerLocations.filter(t => t.id == values.locationConfirm)[0]) || {}
  const locationName = locationObj.name;
  const payload = {
    trialId: values.trialNameConfirm,
    patientId: values.patientId,
    locationId: values.locationConfirm,
    locationName: locationName,
    kitId: values.kitId,
    modifiedDate: moment(Date.now()).format('MMM D, YYYY h:mm A')
  };
  return (
    <>
      <div className="text-center">
        <ProgressBar step={3} setFormStep={setFormStep} showBackButton={true} />
        { // QR Scanner
          activateQrReader &&
          <>
            <Button
              onClick={() => facingmode === "environment" ? setFacingmode("user") : setFacingmode("environment") }
              className="w-100 mt-3"
              style={{backgroundColor: "grey", fontSize: "18px", color: "white"}}
              ><img src={rotateCameraSvg} style={{width: "24px", margin: "0 10px 4px 0"}} />Switch Camera</Button>
            <QrReader
              delay={500}
              onError={e => e}
              facingMode={facingmode}
              onScan={(data) => {
                data && setFieldValue('kitId', data);
                data && setFieldTouched('kitId', true, true);
                data && setActivateQrReader(false);
                data && setScanComplete(true);
              }}
            />
            <h4 className="my-3">Center QR code to scan</h4>
          </>
        }

        { // RE-Scan QR button
          ((!activateQrReader && scanComplete) || manualInput) && <>
          <Button color="primary" className="btn-block p-3"
            style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
            color="primary"
            onClick={() => {
              setFieldValue('kitId', '');
              setFieldTouched('kitId', false, true);
              setActivateQrReader(true);
              setScanComplete(false);
              setManualInput(false);
            }
          }>
            <i className={"mdi mdi-crop-free font-size-20 mr-2"} style={{verticalAlign: 'middle'}}></i>
            Rescan QR code
          </Button><br /></>
        }

        { // Scan result icon
          !activateQrReader && scanComplete && !manualInput &&
          <div style={{
            display: 'inline-block',
            clear: 'both',
            marginTop: 20
          }}>
            <div style={{
              width: 240,
              height: 240,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: (errors.kitId || errors.duplicateKit || errors.wrongKitType) ? 'red' : 'green',
              flexDirection: 'column'
            }}>
              <div><i className={`fas fa-${(errors.kitId || errors.duplicateKit || errors.wrongKitType) ? 'times' : 'check'}-circle fa-5x`}></i></div>
              <div className="pt-2" style={{fontSize: "16px"}}>{errors.kitId
                ? "Invalid swab kit ID, try again."
                : errors.duplicateKit
                  ? "Duplicate swab kit ID, try again."
                  : errors.wrongKitType
                    ? "Wrong swab kit type, try again"
                    : `${
                        (/^PY-/.test(values.kitId) && "Psychedelics Genetic Test") ||
                        (/^(JL|TR)-/.test(values.kitId) && "THC Genetic Test") ||
                        (/^(MJ)-/.test(values.kitId) && "Cannabis Genetic Test") ||
                        (/^(PG|BA)-/.test(values.kitId) && "CBD Genetic Test")
                      } swab scan`
              }</div>
            </div>
          </div>
        }
      </div>

      <div className="mt-3">
        <TextField key={1} fullWidth field="kitId" disabled={!manualInput} placeholder="" {...formVars}
          name={
            <span style={{color: "rgb(50, 100, 245)"}}
              onClick={() => {
              setActivateQrReader(false);
              setManualInput(true);
            }}>
              <i className={"mdi mdi-keyboard-close font-size-14 mr-1"} />
              Manual edit
            </span>
          }
        />
        {errors && errors.duplicateKit && <div style={{color: '#f46a6a', margin: '0 30px 0 30px'}}>{errors.duplicateKit}</div>}
        {errors && errors.wrongKitType && <div style={{color: '#f46a6a', margin: '0 30px 0 30px'}}>{errors.wrongKitType}</div>}

        <div className="text-right m-4 mt-5">
          <Button color="primary" className="btn-block p-3" disabled={!!(errors.kitId || !touched.kitId || errors.duplicateKit || errors.wrongKitType)}
            style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
            onClick={() => {
            if(errors.kitId || !touched.kitId) {
              setFieldTouched('kitId', true, true);
            } else {
              if(!(errors && (errors.duplicateKit || errors.wrongKitType))) {
                setFormStep(5);
                dispatch(actionScanKit(payload))
                .then(() => { setRefreshData(); } );
              }
            }
          }}>Submit</Button>
        </div>
      </div>
    </>
  )
}

export function Step5(props) {
  const dispatch = useDispatch();
  const { setFormStep, values, trials, resetForm, setActiveHomeTab, scannerLocations, setActiveTab } = props;
  const trialObj = trials.filter(t => t.id == values.trialNameConfirm)[0] || {};
  const trialIndex = trials.findIndex(t => t.id == values.trialNameConfirm);
  const trialName = trialObj.name;
  const locationObj = (scannerLocations && scannerLocations.filter(t => t.id == values.locationConfirm)[0]) || {};
  const locationName = locationObj && locationObj.trialLocations && locationObj.trialLocations.name;
  return (
    <>
      <ProgressBar step={4} setFormStep={setFormStep} showBackButton={false} />
      <div className="text-center mt-5">
        <h4 className="my-3" style={{color: 'green'}}>Swab ID registration complete</h4>
        <div className="text-left" style={{display: "inline-block"}}>
          <span>Group Name: {trialName}</span><br />
          <span>Location: {locationName}</span><br />
          <span>Subject ID: {values.patientId}</span><br />
          <span>Swab ID: {values.kitId}</span><br />
          <span>Test Type: {
            (/^PY-/.test(values.kitId) && "Psychedelics Genetic Test") ||
            (/^(JL|TR)-/.test(values.kitId) && "THC Genetic Test") ||
            (/^(MJ)-/.test(values.kitId) && "Cannabis Genetic Test") ||
            (/^(PG|BA)-/.test(values.kitId) && "CBD Genetic Test")
          }</span><br />
        </div>
      </div>
      <div className="text-center m-5">
      <Button color="primary" className="btn-block p-3 mt-3"
        style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
        onClick={() => {
          resetForm();
          setFormStep(1);
          dispatch(actionLocations(trialIndex, []));
          setActiveTab(trialIndex);
          setActiveHomeTab(2);
        }}>View scan history &gt;</Button>
      <Button color="primary" className="btn-block p-3 mt-4"
        style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
        onClick={() => {
          setFormStep(1);
          resetForm();
          setActiveHomeTab(1);
        }}>Scan another Swab ID &gt;</Button>
      </div>
    </>
  )
}

export function BackButton(props) {
  const { onClick } = props;
  return (
    <div className="mini-stats-wid mb-2">
      <div onClick={onClick} className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
        <span className="avatar-title"
          style={{background: "linear-gradient(315deg, #3264F5, #7202BB)"}}
        >
          <i className={"mdi mdi-keyboard-backspace font-size-24"}></i>
        </span>
      </div>
    </div>
  )
}

export function ProgressBar(props) {
  const { step, setFormStep, showBackButton } = props;
  return (
    <div style={{display: "flex", flexDirection: "row"}}>
      { showBackButton && <BackButton onClick={() => { setFormStep(step) }} /> }
      <div style={{height: "4px", flexGrow: 1, margin: "7px 0 0 10px"}}>
        <div className="text-left" style={{color: "#3264f5", fontSize: "11px", fontWeight: "bold"}}>STEP: {step}</div>
        <Progress style={{height: "4px", flexGrow: 1}} value={step*25} />
      </div>
    </div>
  )
}

export function DropdownSelect(props) {
  const { selectText, dropdownData, value, setValue, error } = props;
  const thisOption = (dropdownData.filter(d => d.value == value))[0] || {};
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const dropdownToggleStyle = {
    display: "flex",
    flexDirection: "row",
    width: "calc(100% - 40px)",
    outline: "none",
    border: "none",
    borderBottom: "1px solid black",
    borderRadius: 0,
    margin: "0 20px 0 20px",
    fontSize: "16px",
  };
  const dropdownMenuStyle = {
    marginLeft: "20px",
    width: "calc(100% - 40px)",
  }
  const dropdownItemStyle = {
    display: "block",
    width: "100%",
    outline: "none",
    border: "none",
    borderRadius: 0,
    margin: "0 20px 0 20px",
    fontSize: "16px",
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{display: 'flex'}}>
      <DropdownToggle caret color={error ? 'outline-danger' : 'outline-light'} style={dropdownToggleStyle}>
        <div className="text-left" style={{flexGrow: 1, height: 37, paddingTop: "7px"}}>{thisOption.label || selectText}</div>
        <div className="text-right"><i className={`mdi mdi-chevron-${dropdownOpen ? 'up' : 'down'} font-size-24 mr-2`}></i></div>
      </DropdownToggle>
      <DropdownMenu style={dropdownMenuStyle}>
        <DropdownItem key={-1} onClick={() => { setValue(''); }} style={dropdownItemStyle}>{selectText}</DropdownItem>
        { dropdownData.map((d, i) => <DropdownItem key={i} onClick={() => { setValue(d.value) }} style={dropdownItemStyle}>{d.label}</DropdownItem>)}
      </DropdownMenu>
    </Dropdown>
  )
}

export function EditScannedKitForm(props) {
  const [ refreshData, setRefreshData ] = useContext(RefreshDataContext);
  const { scanId, swabHistory, trial, closeModal } = props;
  const dispatch = useDispatch();
  const thisTest = [
    ...(((swabHistory.psychedelicsTests && swabHistory.psychedelicsTests) || []).filter(t => t.id == scanId)),
    ...(((swabHistory.cannabisTests && swabHistory.cannabisTests) || []).filter(t => t.id == scanId)),
    ...(((swabHistory.cbdCannabisTests && swabHistory.cbdCannabisTests) || []).filter(t => t.id == scanId)),
    ...(((swabHistory.thcCannabisTests && swabHistory.thcCannabisTests) || []).filter(t => t.id == scanId))
  ][0] || [];
  const initialValues = {
    id: thisTest.id || 0,
    patientId: thisTest.patientId || '',
    type: thisTest.type
  };
  const validationSchema = Yup.object().shape({
    patientId: Yup.string()
      .required('This is a required field'),
  });
  const serverErrorFields = {};
  return (
    <>
      <Card className="mini-stats-wid m-2" style={{border: "1px solid #F0F0F0"}}>
        <CardBody>
          <Media>
            <Media body>
              <p className="text-muted font-weight-medium mb-1">
                Test Type:
                <span className="ml-2 text-priimary font-weight-bold">{
                  {
                    cbd: "CBD Genetic Test swab scan",
                    thc: "THC Genetic Test swab scan",
                    cannabis: "Cannabis Genetic Test swab scan",
                    psychedelic: "Psychedelics Genetic Test swab scan"
                  }[thisTest.type]
                }</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Subject ID:
                <span className="ml-2 text-priimary font-weight-bold">{thisTest.patientId}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Location:
                <span className="ml-2 text-priimary font-weight-bold">{thisTest.scannerLocations && thisTest.scannerLocations.trialLocations && thisTest.scannerLocations.trialLocations.name}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Swab ID:
                <span className="ml-2 text-priimary font-weight-bold">{thisTest.kitId}</span>
              </p>
              <p className="text-muted font-weight-medium mb-1">
                Scan Date:
                <span className="ml-2 text-priimary font-weight-bold">{thisTest.createdDate && moment(thisTest.createdDate).format("MMM D, YYYY h:mm A")}</span>
              </p>
            </Media>
          </Media>
        </CardBody>
      </Card>
      { trial.status === "ACTIVE" ?
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{ id: initialValues.id, patientId: initialValues.patientId, type: initialValues.type }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              dispatch(actionUpdateTest(values))
              .then(() => { closeModal(); setRefreshData(); } );
              setSubmitting(false);
            }}
          >
            {({values, errors, touched, resetForm, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
              const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema, serverErrorFields};
              return (
                <Form onSubmit={handleSubmit}>
                  <HiddenField field="id" />
                  { thisTest.reportDate != null
                    ? <ReadonlyField key={1} fullWidth name="Subject ID" value={values.patientId} field="patientId" />
                    : <TextField key={1} fullWidth name="Edit Subject ID" field="patientId" placeholder="Enter the subject's ID..." {...formVars} />
                  }
                  <FormButtons submit disabled={thisTest.reportDate != null} isSubmiting={isSubmitting} />
                </Form>
              )}}
          </Formik>
          { thisTest && !thisTest.reportDate &&
            <Button color="danger" style={{position: 'relative', left:'25px', top:'-73px', marginBottom: "-40px"}} onClick={() => {
              dispatch(actionDeleteTest(thisTest.kitId))
              .then(() => { closeModal(); setRefreshData(); } );
            }}>Delete</Button>
          }
        </> :
        <div className="text-center">
          <Button
            color="primary"
            onClick={() => { closeModal(); }}
            className="mt-3 mb-2"
            style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "12px"}}
          >Close</Button>
        </div>
      }
    </>
  )
}