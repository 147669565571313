import React from "react";
import {Redirect, Route} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {store} from "../store/store";

const AppRoute = ({component: Component, layout: Layout, isAuthProtected, ...rest}) => (
  <Route
    {...rest}
    render={props => {
      function isValidUserJWT() {
        let token = store.getState().UserAccessToken;
        if(token && token.token) token = token.token;
        if (token && !(typeof token === 'object' && !Object.keys(token).length)) {
          const decodedJwt = jwtDecode(token);
          if (decodedJwt.exp * 1000 >= (new Date()).getTime()) {
          return true;
          }
        }
        return false;
      }

      if(isAuthProtected && !isValidUserJWT()){
        return (
          <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;

