import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link} from 'react-router-dom'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Row, Col, Alert, Spinner, Card, CardBody, Container, Button } from 'reactstrap';
import { TextField } from '../../components/Form/formikFields';
import { FormButtons } from '../../components/Form/formHelpers';
import { forgotPassword, forgotPasswordReset } from '../../actions/auth';
import profile from '../../assets/images/profile-img.png'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('This is a required field')
    .email('Not a valid email address'),
});

export function ForgotPassword(props) {
  const dispatch = useDispatch();
  const { setModal, email } = props;
  const [passwordRequestSent, setPasswordRequestSent] = useState(false);
  useEffect(() => () => dispatch(forgotPasswordReset()), [dispatch]); // hook for componentWillUnmount

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col className="m-0 p-0">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Forgot Password</h5>
                        <p>Reset your password to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid"/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={require('../../assets/images/favicon.ico')} alt="" className="rounded-circle" height="34"/>
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{email: email || ''}}
                      validationSchema={validationSchema}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        await dispatch(forgotPassword('scanner:' + values.email));
                        setPasswordRequestSent(true);
                        setSubmitting(false);
                      }}
                    >
                      {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
                        const formVars = {values, errors, handleChange, handleBlur, touched, validationSchema};
                        return (
                          <Form onSubmit={handleSubmit}>
                            <React.Fragment>
                              <Row>
                                <Col>
                                  { email
                                    ? <p>Click the button below to send a password reset email the the address in the email field.</p>
                                    : <p>Please enter the email associated with your account to reset your password.</p>
                                  }
                                  <TextField fullWidth name="Email" field="email" placeholder="Enter email" {...formVars} />
                                  { passwordRequestSent
                                    ? <div>
                                        <Alert className="mt-4" color="success">
                                          <strong>Reset Password Email Sent!</strong><br /><br />
                                          An email with instructions for resetting your password has been sent to: {values.email}<br />
                                          <div className="mt-4 text-right">
                                            <Button onClick={() => setModal(false)} color="outline-primary">Close</Button>
                                          </div>
                                        </Alert>
                                      </div>
                                    : <FormButtons submit="Send Reset Email" isSubmitting={isSubmitting} />
                                  }
                                </Col>
                              </Row>
                            </React.Fragment>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Lobo Genetics Inc.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
};
