import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import { CenteredModal, setModal } from '../Common/Modals';
import { PatientsTable, FilterByLocation } from './Tables';
import { getThisTrialsSwabHistory } from '../Common/tests';
import { getAllLocationOptions, getTrialLocationOptions } from '../Common/locations';
import { EditScannedKitForm } from '../Form/Forms';
import { actionLocations } from '../../actions/flags';

export function TrialTabs(props) {
  const dispatch = useDispatch();
  const { trials, swabHistory, tables, scannerLocations, activeTab, setActiveTab } = props;
  const { modals, locations } = useSelector(state => state.Flags);
  const locationOptions = getAllLocationOptions(trials, scannerLocations);
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const locationData = trials.map((t) => { return({trial: t.id, locations: scannerLocations
    .filter(f => f.trialLocations.trials.id === t.id)
    .map((l) => { return({label: l.trialLocations.name, value: l.id}) })
    .sort((a, b) => (b.label.toLowerCase() < a.label.toLowerCase()) - (b.label.toLowerCase() > a.label.toLowerCase()))
  }) });
  return (
    <>
      <Nav fill tabs>
        { trials && trials.map((t, i) => {
            return <NavItem key={i}>
              <NavLink
                style={{
                  border: 'none',
                  borderBottom: `2px solid ${activeTab === i ? '#3264F5' : '#D8D8D8'}`,
                  color: activeTab === i ? '#111' : '#AAA'
                }}
                className={classnames({ active: activeTab === i })} onClick={() => { toggle(i); }}>
                {t.name}
              </NavLink>
            </NavItem>;
          }
        )}
      </Nav>
      <TabContent activeTab={activeTab} style={{border: "none", borderTop: 'none'}}>
        { trials && trials.map((t, i) => {
            if(activeTab === i) {
              const setLocationFilter = (locations) => dispatch(actionLocations(i, locations));
              const trialLocations = locationData.filter(l => l.trial == t.id)[0].locations;
              const locationFilter = (locations && locations[i]) || trialLocations;
              const locationCount = trialLocations.length;
              const swabHistoryData = getThisTrialsSwabHistory(t, swabHistory);
              const thisTrialsLocationOptions = getTrialLocationOptions(locationOptions, t.id);
              return (
                <TabPane tabId={i} key={i}>
                  <Row className="p-0 m-0">
                    <Col className="px-0">
                      <Card body className="p-0 m-0">
                        <CardBody className="p-0">
                          { locationCount ? <>
                            { <div className="text-left mx-3 pt-4 pb-0 font-weight-bold">Group status: {t.status}</div> }
                            { locationCount > 1 && <div className="pt-4">
                                <FilterByLocation locationsData={thisTrialsLocationOptions} locationFilter={locationFilter} setLocationFilter={setLocationFilter} />
                              </div>
                            }
                            { tables.includes("patients") && <div className="m-2">
                                <PatientsTable
                                  trialId={t.id}
                                  trials={trials}
                                  data={swabHistoryData}
                                  locationFilter={locationFilter}
                                  openEditScannedKitModal={(kitId) => setModal(dispatch, modals, 'editScan', kitId, t.id)}
                                />
                              </div>
                            }
                          </> : <>
                            <p className="p-4 m-0 text-center">This trial currently has no locations.</p>
                          </>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <CenteredModal title="Scanned swab kit" modals={modals} setModal={setModal} type="editScan" id={t.id}>
                    <EditScannedKitForm
                      trialId={t.id}
                      trial={t}
                      scanId={modals.editScan[t.id]}
                      swabHistory={swabHistory}
                      closeModal={() => setModal(dispatch, modals, 'editScan', false, t.id)}
                    />
                  </CenteredModal>
                </TabPane>
              )
            }
            else return false;
          }
        )}
      </TabContent>
    </>
  );
}
