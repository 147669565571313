import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import MultiSelect from "react-multi-select-component";
import { Table, Button } from 'reactstrap'
import { RefreshDataContext } from '../../App';

const tdStyle = {
  border: "1px solid #eee",
  verticalAlign: "top",
  padding: "10px 2px 10px 2px",
  fontSize: "12px"
};

export function PatientsTable(props) {
  const { locationFilter, openEditScannedKitModal } = props;
  const [ refreshData, setRefreshData, activeHomeTab, setActiveHomeTab, toggle ] = useContext(RefreshDataContext);
  let data = props.data || [];
  if(locationFilter && locationFilter.length > 0) data = (props.data || []).filter(d => locationFilter.map(lf => lf.label).includes(d.locationName));
  const dataOrderedByDateDesc = data.sort((a, b) => (a.modifiedDate < b.modifiedDate) - (a.modifiedDate > b.modifiedDate));
  return (
    <>
      { data.length ? <>
          <Table className="m-0 mt-4">
            <thead className="thead-light">
              <tr>
                <th style={tdStyle} className="text-center">Subject ID</th>
                <th style={tdStyle} className="text-center">Location</th>
                <th style={tdStyle} className="text-center">Swab ID</th>
                <th style={tdStyle} className="text-center">Scan Date</th>
              </tr>
            </thead>
            <tbody>
              { dataOrderedByDateDesc.map((d, i) =>
                  <tr key={i}
                    className={`${moment.duration(moment().diff(d.lastModifiedDate)).asSeconds() < 30 ? 'table-success' : 'table-light'}`}
                    onClick={() => openEditScannedKitModal(d.id) }
                    style={{backgroundColor: "green"}}
                  >
                    <td style={tdStyle} className="text-center">{d.patientId}</td>
                    <td style={tdStyle} className="text-center">{d.locationName}</td>
                    <td style={tdStyle} className="text-center"><a style={{whiteSpace: 'nowrap'}} title={d.kitId}>{d.kitId.length > 10 ? d.kitId.substring(0,6) + '…' : d.kitId}</a></td>
                    <td style={tdStyle} className="text-center">{d.createdDate && moment(d.createdDate).format("MMM D, YYYY h:mm A")}</td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </>
        : <div className="text-center m-3 pb-3 font-weight-bold">
            <p className="py-4">No swab kits registered.</p>
            <Button
              color="primary"
              className="btn-block p-3"
              style={{background: "linear-gradient(to right, #3264F5, #7202BB)", borderRadius: "10px", fontSize: "18px"}}
              onClick={() => setActiveHomeTab(1)}
            >Start Scanning Now</Button>
        </div>
      }
    </>
  );
}

export function FilterByLocation(props) {
  const { locationsData, locationFilter, setLocationFilter } = props;
  useEffect(() => {setLocationFilter(locationFilter)}, []);
  return (
    <div style={{padding: "10px"}}>
      <span className="ml-2 font-weight-bold">Filter results:</span>
      <MultiSelect
        overrideStrings={{
          allItemsAreSelected: "All locations selected",
          selectAll: "All Locations",
          selectSomeItems: "Select Locations..."
        }}
        className="mt-1"
        options={locationsData}
        value={locationFilter}
        onChange={setLocationFilter}
        disableSearch
      />
    </div>
  );
}
