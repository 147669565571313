import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { RefreshDataContext } from '../../App';

export default function Header() {
  const [ refreshData, setRefreshData, activeHomeTab, setActiveHomeTab, toggle ] = useContext(RefreshDataContext);
  return (
    <React.Fragment>
      <header id="page-topbar" style={{height: "65px", background: "linear-gradient(to bottom, #7202BB, #3264F5)"}}>
        <Link to="/">
          <div style={{color: 'white', margin: "15px 0 0 20px", fontSize: "24px"}}>
            {activeHomeTab == 1 && 'Lobo Scanner'}
            {activeHomeTab == 2 && 'Scan History'}
            {activeHomeTab == 3 && 'Account'}
          </div>
        </Link>
      </header>
    </React.Fragment>
  );
}
