import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { Col, Row, TabContent, TabPane, Button } from 'reactstrap'
import { withNamespaces } from 'react-i18next'
import { RefreshDataContext } from '../../App';
import { TrialTabs } from '../../components/Trials/Tabs';
import { RegisterPatientKitForm } from '../../components/Form/Forms';
import { ForgotPassword } from '../../components/Authentication/ForgotPassword';
import { StandardCenteredModal } from '../../components/Common/Modals';

function Home() {
  const { trials, swabHistory, scannerLocations } = useSelector(state => state.Trials);
  const [ refreshData, setRefreshData, activeHomeTab, setActiveHomeTab, toggle ] = useContext(RefreshDataContext);
  const [activeTab, setActiveTab] = useState(0);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if(Object.keys(swabHistory).length > 0 && swabHistory.scannerStatus === "INACTIVE") {
      history.push('/login');
    }
    else {return}
  }, [swabHistory]);
  return (
    <div className="page-content p-0 mb-5">
      <div className="pt-lg-5" style={{marginTop: '65px'}}>
        <TabContent activeTab={activeHomeTab} style={{
            backgroundColor: "#FAFAFA",
            padding: 0,
          }}>
          <TabPane tabId={1} key={1}>
            <Row className="m-0">
              <Col className="pt-4 p-2">
                <RegisterPatientKitForm
                  trials={trials}
                  scannerLocations={scannerLocations}
                  setActiveHomeTab={setActiveHomeTab}
                  setActiveTab={setActiveTab}
                  swabHistory={swabHistory}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={2} key={2}>
            <Row className="m-0">
              <Col className="p-0 mt-4">
                <TrialTabs
                  trials={trials}
                  scannerLocations={scannerLocations}
                  swabHistory={swabHistory}
                  tables={['patients']}
                  formModals={['assignScanner']}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={3} key={3}>
            <Row className="m-0">
              <Col className="p-2 ml-4">
                <h4 className="my-3">Account page</h4>
                <div className="mb-3 mt-5">
                  <i className={"mdi mdi-email-outline mr-1"}></i> {swabHistory.email}
                </div>
                <div className="mb-3">
                  <a href="https://static1.squarespace.com/static/5f11e8f9eef960672094d1e4/t/62053c04b3f56046a4d38ff9/1644510213061/Clinical+scanner+Guide+-+Halugen+-+Scanner.pdf" target="_blank"><i className={"mdi mdi-file-document mr-1"}></i>&nbsp;Clinical Scanner Guide</a>
                </div>
                <div className="my-3 text-primary" onClick={() => setChangePasswordModal(true)} style={{cursor: "pointer"}}>
                  <i className={"mdi mdi-lock mr-2"}></i>Change Password
                </div>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                color="primary"
                className="p-3 mt-5"
                onClick={() => history.push('logout')}
                style={{
                  background: "linear-gradient(to right, #3264F5, #7202BB)",
                  borderRadius: "10px",
                  fontSize: "18px",
                  width: "80%"
                }}
              >Logout</Button>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <StandardCenteredModal title="" modal={changePasswordModal} setModal={setChangePasswordModal} className="m-0 p-0">
        <ForgotPassword setModal={setChangePasswordModal} email={swabHistory.email} />
      </StandardCenteredModal>
    </div>
  );
}

export default withNamespaces()(Home);
