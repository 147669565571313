import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import { actionFetchTrials, actionFetchSwabHistory } from './actions/trials';
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { Spinner } from 'reactstrap'
import "./assets/scss/theme.scss";

export const RefreshDataContext = createContext([{}, () => {}]);

function App() {
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(0);
  const { swabHistory } = useSelector(state => state.Trials);
  const userAccessToken = useSelector(state => state.UserAccessToken);
  let token;
  if (userAccessToken && typeof userAccessToken === 'object') token = userAccessToken.token;
  else token = userAccessToken;
  useEffect(() => { if(token) {dispatch(actionFetchTrials())} else {return} }, [dispatch, token, refreshData]);
  useEffect(() => { if(token) {dispatch(actionFetchSwabHistory())} else {return} }, [dispatch, token, refreshData]);
  const [activeHomeTab, setActiveHomeTab] = useState(1);
  const toggle = tab => {
    if(activeHomeTab !== tab) setActiveHomeTab(tab);
  }

  if(!Object.keys(swabHistory).length && token) return (
    <div className="loadingOverlay">
      <Spinner color="primary" />
      <div className="text-primary mt-2 ml-2" style={{fontSize: "9px"}}>Loading...</div>
    </div>
  );
  else return (
    <RefreshDataContext.Provider value={[refreshData, () => setRefreshData(refreshData+1), activeHomeTab, setActiveHomeTab, toggle]}>
      <BrowserRouter>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={HorizontalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </Switch>
      </BrowserRouter>
    </RefreshDataContext.Provider>
  );
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);
