import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { RefreshDataContext } from '../../App';

const style = {
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
}

const phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}

const Footer = () => {
  const [ refreshData, setRefreshData, activeHomeTab, setActiveHomeTab, toggle ] = useContext(RefreshDataContext);
  return (
    <React.Fragment>
      <div style={phantom}>
      <footer className="footer pt-2" style={style}>
        <Container fluid={true}>
          <Row>
            <Col onClick={() => toggle(1)} style={{color: activeHomeTab === 1 ? '#3264F5' : '#828282'}}>
              <i className="fas fa-camera fa-2x"></i>
              <div>Scan</div>
            </Col>
            <Col onClick={() => toggle(2)} style={{color: activeHomeTab === 2 ? '#3264F5' : '#828282'}}>
              <i className="fas fa-history fa-2x"></i>
              <div>History</div>
            </Col>
            <Col onClick={() => toggle(3)} style={{color: activeHomeTab === 3 ? '#3264F5' : '#828282'}}>
              <i className="fas fa-user fa-2x"></i>
              <div>Account</div>
            </Col>
          </Row>
        </Container>
      </footer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
