import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { Provider } from 'react-redux'
import { store } from './store/store'
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: "https://a7275b410b1142b6a53e0fe9f7f2a903@o379033.ingest.sentry.io/5414152",
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'clinical-dashboard-scanner@1.0.0',
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
