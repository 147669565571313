const initialState = {
  modals: {
    editScan: {},
  },
  locations: {}
};

export const Flags = (state = initialState, action) => {
  switch (action.type) {
    case 'MODALS':
      return {
        ...state,
        modals: action.payload
      };
    case 'LOCATIONS':
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.trial]: action.locations
        }
      };
    default:
      return state;
  }
};

