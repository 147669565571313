import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'reactstrap';
import { actionModals } from '../../actions/flags';

export function setModal(dispatch, modals, type, value, id) {
  if(id) dispatch(actionModals({...modals, [type]: {...modals[type], [id]: value }}) );
  else dispatch(actionModals({...modals, [type]: value }) );
}

export function CenteredModal(props) {
  const dispatch = useDispatch();
  const {title, children, modals, setModal, type, id, className, closeButton} = props;
  let modal;
  if(id) modal = (modals && modals[type] && modals[type][id]) || false;
  else  modal = (modals && modals[type]) || false;
  return (
    <Modal isOpen={!!modal} toggle={ () => setModal(dispatch, modals, type, !modal, id)  } centered={true} contentClassName="noBorder">
      { title && <div className="modal-header" style={{background: "linear-gradient(to bottom, #7202BB, #3264F5)"}}>
          <h5 className="modal-title mt-0" style={{color: "white"}}>{title}</h5>
          <button type="button" onClick={() => setModal(dispatch, modals, type, !modal, id)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style={{color: "white"}}>&times;</span>
          </button>
        </div>
      }
      <div className={`modal-body ${className}`}>
        {children}
      </div>
      {closeButton &&
        <div className="text-center">
          <Button color="primary" size="sm" className="m-2 w-25" onClick={() => setModal(dispatch, modals, type, !modal, id)}>Close</Button>
        </div>
      }
    </Modal>
  )
};

export function StandardCenteredModal(props) {
  const {title, children, modal, setModal, className, closeButton} = props;
  return (
    <Modal isOpen={!!modal} toggle={() => setModal(!modal)} centered={true}>
      { title && <div className="modal-header">
          <h5 className="modal-title mt-0">{title}</h5>
          <button type="button" onClick={() => setModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
      <div className={`modal-body ${className}`}>
        {children}
      </div>
      {closeButton &&
        <div className="text-center">
          <Button color="primary" size="sm" className="m-2 w-25" onClick={() => setModal(false)}>Close</Button>
        </div>
      }
    </Modal>
  )
};

export function WideCenteredModal(props) {
  const {title, children, modal, setModal} = props;
  return (
    <Modal isOpen={!!modal} toggle={() => setModal(!modal)} centered={true} className="modal-lg">
      { title && <div className="modal-header">
          <h5 className="modal-title mt-0">{title}</h5>
          <button type="button" onClick={() => setModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
      <div className="modal-body">
        {children}
      </div>
    </Modal>
  )
};

