import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { doLogout } from '../../actions/auth';
import { RefreshDataContext } from '../../App';

function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ refreshData, setRefreshData, activeHomeTab, setActiveHomeTab, toggle ] = useContext(RefreshDataContext);
  useEffect(() => { dispatch(doLogout()) }, []);
  useEffect(() => { setActiveHomeTab(1) }, []);
  useEffect(() => { history.push('/login') }, []);
  return <div>Logging out...</div>;
}

export default Logout;
