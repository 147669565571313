import React from 'react'

import {Alert, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row, Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import {AvField, AvForm} from 'availity-reactstrap-validation'
import profile from '../../assets/images/profile-img.png'
import {isNullOrUndefined} from '../../lib/helper'
import { ForgotPassword } from '../../components/Authentication/ForgotPassword';
import { StandardCenteredModal } from '../../components/Common/Modals';

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isAuthenticatingAndLoading: false,
      errorMessage: undefined,
      supportModal: false,
      forgotPasswordModal: false
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.setState({isAuthenticatingAndLoading: true, errorMessage: undefined})
    this.props.doLogin(values.email, values.password).then(res => {
      Promise.all([
          //this.props.adminFetchAllProducts()
        ]).then(all => {
        this.props.history.push('/home')
      }).catch(err => {
        this.props.history.push('/home')
      })
    }).catch(err => {
      this.setState({isAuthenticatingAndLoading: false, errorMessage: err.title})
    })
  }

  componentDidMount() {
    const { UserAccessToken } = this.props;
    let token;
    if (UserAccessToken && typeof UserAccessToken === 'object') token = UserAccessToken.token;
    else token = UserAccessToken;
    if(!token) this.props.actionClearSwabHistory();
  }

  render() {
    const { swabHistory } = this.props.Trials;
    return (
      <React.Fragment>
        {this.renderSupportModal()}
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Sign in</h5>
                          <p>Swab kit scanner</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid"/>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                              <img src={require('../../assets/images/favicon.ico')} alt=""
                                   className="rounded-circle" height="34"/>
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      { swabHistory && swabHistory.scannerStatus && swabHistory.scannerStatus === "INACTIVE" &&
                        <Alert color="warning">
                          <strong>Account: {swabHistory.email}</strong><br /><br />This account's status is <strong>{swabHistory.scannerStatus}</strong>. Please log in with a different account to use the Swab Kit scanner.<br />
                          <div className="mt-3 text-center">
                            <a href="/logout">
                              <Button color="warning">Close</Button>
                            </a>
                          </div>
                        </Alert>
                      }
                      <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        <div className="form-group">
                          <AvField name="email" label="Email" className="form-control" placeholder="Enter email" type="text" required/>
                        </div>
                        <div className="form-group">
                          <AvField name="password" label="Password" type="password" required placeholder="Enter Password"/>
                        </div>
                        {!isNullOrUndefined(this.state.errorMessage) ?
                          <div style={{color: 'red'}}>
                            {this.state.errorMessage}
                          </div>
                          : null}
                        {!this.state.isAuthenticatingAndLoading ?
                          <div className="mt-3">
                            <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In
                            </button>
                          </div>
                          :
                          <div className='w-100 d-flex justify-content-center'>
                            <div className='spinner-border text-primary m-1' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </div>}
                      </AvForm>
                    </div>
                    <div className="text-center pt-3 pb-1">
                      <span className="fauxLink" onClick={() => {this.setState({forgotPasswordModal: true})}}>Forgot password?</span>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} Lobo Genetics Inc.</p>
                </div>
                <div className="mt-3 text-center" onClick={() => {
                  this.setState({supportModal: true})
                }}>
                  <p className='pointer' style={{textDecoration: 'underline'}}>
                    <i className="bx bx-support font-size-16 align-middle mr-1"></i>Support
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <StandardCenteredModal title="" modal={this.state.forgotPasswordModal} setModal={(newState) => this.setState({forgotPasswordModal: newState})} className="m-0 p-0">
          <ForgotPassword setModal={(newState) => this.setState({forgotPasswordModal: newState})} />
        </StandardCenteredModal>
      </React.Fragment>
    )
  }

  renderSupportModal() {
    return (
      <Modal isOpen={this.state.supportModal}
             role="dialog"
             autoFocus={true}
             centered={true}
             className="exampleModal"
             size={'md'}
             tabIndex="-1"
             toggle={() =>  this.setState({supportModal: false})}
      >
        <div className="modal-content">
          <ModalHeader toggle={() =>  this.setState({supportModal: false})}>
            Support
          </ModalHeader>
          <ModalBody>
            <a href="mailto:clinical@lobogene.com">Contact Support</a>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}

export default Login

