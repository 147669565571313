import React from 'react';
import {Redirect} from "react-router-dom";
import LoginContainer from '../pages/Authentication/LoginContainer'
import LogoutContainer from '../pages/Authentication/LogoutContainer'
import RegisterContainer from '../pages/Authentication/RegisterContainer'
import ActivateAccountContainer from '../pages/Authentication/ActivateAccountContainer'
import ResetPasswordContainer from '../pages/Authentication/ResetPassword/ResetPasswordContainer'
import Home from '../pages/Home/Home'

const authProtectedRoutes = [
  {path: "/home", component: Home},
  {path: "/", exact: true, component: () => <Redirect to="/home"/>},
];

const publicRoutes = [
  {path: "/logout", component: LogoutContainer},
  {path: "/login", component: LoginContainer},
  {path: "/register", component: RegisterContainer},
  {path: "/activate-account", component: ActivateAccountContainer},
  {path: "/account/reset/finish", component: ResetPasswordContainer},
];

export {authProtectedRoutes, publicRoutes};
