import React from "react";
import Helmet from "react-helmet";
import queryString from 'query-string'
import {Link} from 'react-router-dom'
import {isNullOrUndefined} from "../../../lib/helper";
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import profile from '../../../assets/images/profile-img.png'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      key: undefined,
      password: undefined,
      cPassword: undefined
    }
    const values = queryString.parse(this.props.location.search)
    if (!isNullOrUndefined(values.key)) {
      this.state.key = values.key
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.resetPassword()
  }

  resetPassword() {
    this.setState({error: false})
    if(this.state.password !== this.state.cPassword){
      this.setState({error: true})
      return;
    }
    this.setState({processingLogin: true})
    this.props.resetPassword(this.state.password, this.state.key).then(res => {
      this.setState({processingLogin: false})
      alert('Success, your password has been changed.')
      this.props.history.push('/sign-in')
    }).catch(err => {
      alert('Something went wrong, please try again.')
      this.setState({processingLogin: false})
    })
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Reset Password | Lobo Genetics Inc.</title>
          <meta itemProp="name" content="Reset Password | Lobo Genetics Inc."/>
          <meta itemProp="description" content="Reset Password for Lobo Genetics Inc."/>
          <meta itemProp="image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="description" content="Reset Password for Lobo Genetics Inc."/>
        </Helmet>
      </>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Reset Password</h5>
                          <p>Please reset your password below.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid"/>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                              <img src={require('../../../assets/images/favicon.ico')} alt=""
                                   className="rounded-circle" height="34"/>
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-label-group px-1 pb-3">
                          <input placeholder="New Password" className="form-control" required="required" aria-label="New Password"
                                type="password" name="password" id="password" minLength={6}
                                onChange={(e) => this.setState({password: e.target.value})}/>
                        </div>
                        <div className="form-label-group px-1">
                          <input placeholder="Confirm Password" className="form-control" required="required" aria-label="Confirm Password"
                                type="password" name="cpassword" id="cpassword" minLength={6}
                                onChange={(e) => this.setState({cPassword: e.target.value})}/>
                        </div>
                        {this.state.error ? <div className='text-danger pt-2 px-2'>
                          Passwords do not match.
                        </div> : null}
                        <fieldset name="fsaction" id="fsaction">
                          <div className="actions text-center px-1 pt-3">
                            <input name="commit" value="Reset Password" type={'submit'} disabled={this.state.processingLogin}
                                  className="btn btn-primary btn-block waves-effect waves-light" data-disable-with="Reset Password"
                            />
                          </div>
                        </fieldset>
                      </form>
                    </div>
                    <div className="text-center pt-1 pb-1">
                      <Link to="/login">Do you have an account? sign in.</Link>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} Lobo Genetics Inc.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ResetPassword
