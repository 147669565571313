export function getAllLocationOptions(trials, scannerLocations) {
  return trials.map((t) => { return({trial: t.id, locations: scannerLocations
    .filter(sl => sl.trialLocations && sl.trialLocations.trials && sl.trialLocations.trials.id == t.id)
    .map(l => { return({label: l.trialLocations.name, value: l.id}) })
    .sort((a, b) => (b.label.toLowerCase() < a.label.toLowerCase()) - (b.label.toLowerCase() > a.label.toLowerCase()))
  })});
}

export function getTrialsDefaultLocation(trialId, locationOptions) {
  const defaultLocationObj = trialId ? (
    ( locationOptions.filter(loc => loc.trial == trialId).length &&
      locationOptions.filter(loc => loc.trial == trialId)
    ) || [{locations: [{value: null}]}]
  )[0].locations[0]
  : null;
  return defaultLocationObj && defaultLocationObj.value;
}

export function getTrialLocationOptions(locationOptions, trialId) {
  return (
    (locationOptions.filter(loc => loc.trial == trialId).length &&
    locationOptions.filter(loc => loc.trial == trialId)) || [{locations: []}]
  )[0].locations;
}
