import React from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';

// Regular expression to identify phone numbers
//export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const phoneRegExp = /^\d{10}$/
export const canadianPostCodeRegExp = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/
export const moneyRegExp = /^\d+(\.\d{1,2})?$/
export const requiredStar = <span style={{color: 'red'}}> *</span>;
export const uuidv4RegExp = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}--/i

export const requiredField = (validationSchema, field, requiredOverride) => (validationSchema.fields[field] && validationSchema.fields[field].exclusiveTests && validationSchema.fields[field].exclusiveTests.required) || requiredOverride;

export function FormButtons(props) {
  const { reset, resetAction, cancel, cancelAction, submit, isSubmitting, align, disabled } = props;
  return (
    <Row key="buttons">
      <Col className={`${align ? align : 'text-right'} m-3`}>
        { cancel && <Button key={1} color="outline-secondary" className="m-2" type="reset"
            onClick={cancelAction}
          >{cancel === true ? 'Cancel' : cancel}</Button>
        }
        { reset && <Button key={2} color="outline-secondary" className="m-2" type="reset"
            onClick={resetAction}
          >{reset === true ? 'Reset' : reset}</Button>
        }
        { submit && <Button key={3} color="outline-primary" className="m-2" type="submit"
            disabled={isSubmitting || !!disabled}
          >{submit === true ? 'Submit' : submit}</Button>
        }
      </Col>
    </Row>
  )
};

export function BasicFormAlerts(props) {
  const { submitSuccess, serverErrors, fullWidth } = props;
  return(
    <React.Fragment>
      { serverErrors && serverErrors.title && <Row>
          { fullWidth || <Col className="col-2 pt-4"></Col> }
          <Col className={fullWidth ? 'col-12' : 'col-10'}>
            <Alert color="danger">{serverErrors.title}</Alert>
          </Col>
        </Row>
      }
      { submitSuccess && <Row>
          { fullWidth || <Col className="col-2 pt-4"></Col> }
          <Col className={fullWidth ? 'col-12' : 'col-10'}>
            <Alert color="success">
              Form submitted successfully!
            </Alert>
          </Col>
        </Row>
      }
    </React.Fragment>
  )
}