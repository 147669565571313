export function getThisTrialsSwabHistory(trial, swabHistory) {
  const swabHistoryData = [];
  if(trial && trial.thcTesting && swabHistory && swabHistory.id) swabHistoryData.push(...swabHistory.thcCannabisTests
    .filter(test => test.trials.id == trial.id)
    .map(test => ({
      id: test.id,
      type: 'thcCannabisTests',
      patientId: test.patientId,
      locationName: (test.scannerLocations && test.scannerLocations.trialLocations && test.scannerLocations.trialLocations.name) || '',
      kitId: test.kitId,
      lastModifiedDate: test.lastModifiedDate,
      createdDate: test.createdDate
    }))
  );
  if(trial && trial.cbdTesting && swabHistory && swabHistory.id) swabHistoryData.push(...swabHistory.cbdCannabisTests
    .filter(test => test.trials.id == trial.id)
    .map(test => ({
      id: test.id,
      type: 'cbdCannabisTests',
      patientId: test.patientId,
      locationName: (test.scannerLocations && test.scannerLocations.trialLocations && test.scannerLocations.trialLocations.name) || '',
      kitId: test.kitId,
      lastModifiedDate: test.lastModifiedDate,
      createdDate: test.createdDate
    }))
  );
  if(trial && trial.cannabisTesting && swabHistory && swabHistory.id) swabHistoryData.push(...swabHistory.cannabisTests
    .filter(test => test.trials.id == trial.id)
    .map(test => ({
      id: test.id,
      type: 'cannabisTests',
      patientId: test.patientId,
      locationName: (test.scannerLocations && test.scannerLocations.trialLocations && test.scannerLocations.trialLocations.name) || '',
      kitId: test.kitId,
      lastModifiedDate: test.lastModifiedDate,
      createdDate: test.createdDate
    }))
  );
  if(trial && trial.psychedelicTesting && swabHistory && swabHistory.id) swabHistoryData.push(...swabHistory.psychedelicsTests
    .filter(test => test.trials.id == trial.id)
    .map(test => ({
      id: test.id,
      type: 'psychedelicsTests',
      patientId: test.patientId,
      locationName: (test.scannerLocations && test.scannerLocations.trialLocations && test.scannerLocations.trialLocations.name) || '',
      kitId: test.kitId,
      lastModifiedDate: test.lastModifiedDate,
      createdDate: test.createdDate
    }))
  );
  return swabHistoryData.sort((b, a) => (b.lastModifiedDate < a.lastModifiedDate) - (b.lastModifiedDate > a.lastModifiedDate));
}
