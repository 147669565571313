import createReducer from './createReducer'

export const Trials = createReducer({
  trials: [],
  scannerLocations:[],
  swabHistory: {},
}, {

  // *** TRIALS *** //
  'FETCH_TRIALS_SUCCESS': (state, action) => { return { ...state,
    trials: action.payload.trials ?
      action.payload.trials
      .sort((a, b) => (b.name.toLowerCase() < a.name.toLowerCase()) - (b.name.toLowerCase() > a.name.toLowerCase()))
      : [],
    scannerLocations: action.payload.scannerLocations
  }},

  'CLEAR_SWAB_HISTORY': (state, action) => { return { ...state,
    swabHistory: {}
  }},

  // *** SWAB HISTORY *** //
  'FETCH_SWAB_HISTORY_SUCCESS': (state, action) => { return { ...state,
    swabHistory: action.payload
  }},

  'SCAN_KIT_SUCCESS': (state, action) => { return { ...state } }

});
