import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Login from './Login'
import {ActionCreators} from '../../actions'

const mapStateToProps = state => ({
  Trials: state.Trials,
  UserAccessToken: state.UserAccessToken
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer
